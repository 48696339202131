import { render, staticRenderFns } from "./RegisterFormPage.vue?vue&type=template&id=f0699f98&scoped=true&"
import script from "./RegisterFormPage.vue?vue&type=script&lang=js&"
export * from "./RegisterFormPage.vue?vue&type=script&lang=js&"
import style0 from "./RegisterFormPage.vue?vue&type=style&index=0&id=f0699f98&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0699f98",
  null
  
)

export default component.exports