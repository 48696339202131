<template>
  <div>
    <div class="vx-row">
      <div :class="colClass">
        <vs-input
          class="w-full"
          label="Nombre comercial *"
          name="tradename"
          v-validate="requiredRules"
          v-model.lazy="model.tradename"
          :danger="hasError('tradename')"
          :danger-text="errorText('tradename')"
          :success="isSuccess('tradename')"
          icon-pack="feather"
          :placeholder="requiredPlaceholder"
          v-on="{input: e => {model.tradename = upper(e)}}"
        />
      </div>
      <div :class="colClass">
        <div class="flex">
          <vue-country-code
            name="dialCode"
            class="vs-custom dial-code"
            data-vv-name="dialCode"
            :enableSearchField="true"
            searchPlaceholderText="Buscar código de país"
            :enabledCountryCode="true"
            @onSelect="onSelect"
            :defaultCountry="preferredCountries"
            :preferredCountries="['MX']"
            :danger="hasError('dialCode')"
            :danger-text="errorText('dialCode')"
            :success="isSuccess('dialCode')">
          </vue-country-code>
          <vs-input
            class="w-full"
            label="Teléfono a 10 dígitos *"
            name="phone"
            type="tel"
            v-validate="phoneRules"
            v-model.lazy="model.phone"
            :danger="hasError('phone')"
            :danger-text="errorText('phone')"
            :success="isSuccess('phone')"
            icon-pack="feather"
            :placeholder="phonePlaceholder"
          />
        </div>
      </div>
      <div :class="colClass">
        <vs-input
          class="w-full"
          label="Página web"
          name="webpage"
          v-validate="webRules"
          v-model.lazy="model.webpage"
          :danger="hasError('webpage')"
          :danger-text="errorText('webpage')"
          :success="isSuccess('webpage')"
          icon-pack="feather"
          :placeholder="webPlaceholder"
        />
      </div>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
export default {
  name: "SupplierDataForm",
  mixins: [inputHelper],
  components: {
  },
  data(){
    return {
      colClass: "vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5",
      requiredRules: 'required',
      optionalRules: '',
      phoneRules: 'required|digits:10',
      requiredPlaceholder: '(Requerido)',
      optionalPlaceholder: '(Opcional)',
      phonePlaceholder: '(Requerido) Ingresa 10 dígitos',
      webRules: 'url',
      webPlaceholder: '(Opcional) Ingresa una url',
      model: {
        tradename: null,
        webpage: null,
        city: null,
        phone: null,
        country_calling_code: null
      },

      preferredCountries: 'MX',
    }
  },
  methods: {
    onSelect({ dialCode }) {
      this.model.country_calling_code = `+${dialCode}`;
    },
    getModel(){
      return this.model;
    },
    async validateForm(){
      return await this.$validator.validateAll();
    },
    validate_birthdate(age){
      let years = this.calculateAgeFromDate(this.model.birth_date);
      return years >= age;
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);
    },
  }
}
</script>
