<template>
  <div>
    <p>
      Gracias por tu interés en formar parte de la Red de los hogares y negocios solares.
      <br><br>
      Compártenos tus datos para que
      <span class="bold">uno de nuestros agentes de venta se ponga en contacto contigo</span>
      y comiences a ahorrar ¡muy pronto!
      <br><br>
    </p>

    <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mb-5" color="danger">
      <span class="font-regular">{{errorMssg}}</span>
    </vs-alert>

    <div class="vx-row">
      <div :class="largeColClass">
        <vs-input
          class="w-full"
          label="Nombre completo *"
          name="name"
          v-validate="requiredRules"
          v-model.lazy="model.name"
          :danger="hasError('name')"
          :danger-text="errorText('name')"
          :success="isSuccess('name')"
          icon-pack="feather"
          :placeholder="requiredPlaceholder"
          v-on="{input: e => {model.name = upper(e)}}"
        />
      </div>
      <div :class="colClass">
        <vs-input
          class="w-full"
          label="Correo electrónico *"
          name="email"
          v-validate="emailRules"
          v-model.lazy="model.email"
          :danger="hasError('email')"
          :danger-text="errorText('email')"
          :success="isSuccess('email')"
          icon-pack="feather"
          :placeholder="emailPlaceholder"
        />
      </div>
      <div :class="colClass">
        <vs-input
          class="w-full"
          label="Teléfono a 10 dígitos *"
          name="phone"
          maxlength="10"
          v-validate="phoneRules"
          v-model.lazy="model.phone"
          :danger="hasError('phone')"
          :danger-text="errorText('phone')"
          :success="isSuccess('phone')"
          icon-pack="feather"
          :placeholder="phonePlaceholder"
        />
      </div>
      <div :class="colClass">
          <vs-select
          placeholder="Seleccione"
          v-validate="requiredRules"
          @input="onStateChange"
          class="w-full"
          label="Estado *"
          v-model="model.state_id"
          >
            <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in states" />
          </vs-select>
      </div>
      <div :class="colClass">
          <vs-select
          placeholder="Seleccione"
          v-validate="requiredRules"
          class="w-full"
          label="Ciudad *"
          :disabled="cities.length === 0"
          v-model.lazy="model.city_id"
          >
            <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in cities" />
          </vs-select>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-12">
        <vue-recaptcha
          v-if="recaptchaSiteKey"
          ref="vrecaptcha"
          @verify="onVerify"
          @expired="onExpired"
          :sitekey="recaptchaSiteKey"></vue-recaptcha>
      </div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import inputHelper from "@mixins/inputHelper";
export default {
  name: "ApplicantLeadForm",
  mixins: [inputHelper],
  components: { VueRecaptcha },
  data() {
    return {
      colClass: "vx-col xxl:w-1/4 lg:w-1/2 md:w-1/2 sm:w-full w-full mb-5",
      largeColClass: "vx-col xxl:w-1/2 lg:w-1/2 w-full mb-5",
      requiredRules: 'required',
      optionalRules: '',
      emailRules: 'required|email',
      emailPlaceholder: '(Requerido) Ingresa una dirección de correo válida',
      phoneRules: 'digits:10',
      phonePlaceholder: 'Ingresa 10 dígitos',
      requiredPlaceholder: '(Requerido)',
      optionalPlaceholder: '(Opcional)',
      recaptcha: null,
      recaptchaSiteKey: null,
      errorMssg: null,
      model: {
        name: null,
        phone: null,
        email: null,
        state_id:null,
        city_id:null,
        recaptcha: null,
      },
      states:[],
      cities:[]
    }
  },
  /*created() {
    let recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', "https://www.google.com/recaptcha/api.js");
    recaptchaScript.defer = true;
    recaptchaScript.async = true;
    document.body.appendChild(recaptchaScript);
  },*/
  async beforeMount() {
    await this.getStates();
    this.recaptchaSiteKey = process.env.VUE_APP_RECPATCHA_SITE_KEY;
  },
  methods: {
    async getStates() {
      this.showLoading(true);
      this.states = [];
      const res = await this.publicApiGet('/api/register/states');
      this.states = res.data;
      this.showLoading(false);
    },
    async onStateChange(){
      if(!this.model.state_id || this.model.state_id === 0 ){
        return;
      }
      this.model.city_id="";
      this.showLoading(true);
      try {
        let res = await this.publicApiGet('/api/register/cities/' + this.model.state_id);
        this.cities = res.data;
        this.showLoading(false);
      }
      catch (e) {
        this.showLoading(false);
        this.warn(e);
      }
    },
    getModel(){
      return this.model;
    },
    async validateForm(){
      this.errorMssg = null
      const valid = await this.$validator.validateAll();
      if(valid){
        if(this.validateCaptcha()){
          this.model.recaptcha = this.recaptcha;
          return true;
        }
      }
      return false;
    },
    validateCaptcha(){
      if(this.recaptcha == null){
        this.errorMssg = "Por favor completa la verificación 'No soy un robot'."
        return false
      }
      return true
    },
    resetForm(){
      this.errorMssg = null
      this.model = {}
      this.resetRecaptcha()
      this.$nextTick(() => {
        this.errors.clear();
        this.$nextTick(() => {
          this.$validator.reset();
        });
      });
    },
    onVerify(response){
      this.recaptcha = response;
    },
    onExpired(){
      this.recaptcha = null
    },
    resetRecaptcha(){
      this.recaptcha = null
      this.$refs.vrecaptcha.reset()
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);
    },
  }
}
</script>

<style scoped>

</style>