<template>
  <div>
    <!-- ONBOARDING DE INVERSIONISTAS -->
    <investor-onboarding-register-page v-if="isInvestor" :referal-info="referalInfo"/>
    <supplier-onboarding-register-page v-else-if="isSupplier" :referal-info="referalInfo"/>
    <applicant-onboarding-register-page v-else-if="isApplicant" :referal-info="referalInfo"/>
    <div v-else class="vx-row no-gutter items-center justify-center p-6">
      <div class="vx-col w-full">
        <vx-card class="login-card">
          <div slot="no-body" class="px-3">
            <div class="vx-row m-0">
              <div class="vx-col w-full p-0" style="min-height: 200px">
                <div class="px-6 pt-5 pb-5">
                  <div class="vx-row mb-4">
                    <div class="vx-col w-full">
                      <!-- TODO-DELETE 29/11/2022 el registro de inversionista se hace desde el onboarding -->
                      <!-- <p class="text-3xl card-sub-title" v-if="isInvestor">Quiero ser <span>Inversionista</span></p> -->
                      <p class="text-3xl card-sub-title" v-if="isSupplier">Quiero ser <span>Instalador</span></p>
                      <p class="text-3xl card-sub-title" v-if="isApplicant">Quiero un <span>crédito</span></p>
                      <hr>
                    </div>
                  </div>

                  <!-- REFERAL CODE MESSAGE -->
                  <!-- TODO-DELETE 29/11/2022 se valida en un nivel superior RegisterFromReferalPage -->
                  <!-- <div v-if="isMounted && fromReferal && validReferalCode"> -->
                  <div v-if="isMounted && fromReferal">
                    <vs-alert class="h-full mb-3" color="success" v-if="referalInfo.type === 'redferido'">
                      Estás utilizando el código de invitación de <span class="bold">{{referalInfo.name}}</span>.
                      Al completar tu registro, ambos obtendrán los beneficios del programa
                      <span class="bold">Re(d)feridos.</span>
                    </vs-alert>

                    <vs-alert class="h-full mb-3" color="success" v-else >
                      Al completar tu registro, estarás apoyando a <span class="bold">{{referalInfo.name}}</span>.
                    </vs-alert>
                  </div>
                  <!-- END REFERAL CODE MESSAGE -->

                  <!-- MAIN FORM DIV -->
                  <div class="main-form" v-if="isMounted && showForm">
                    <template v-if="isApplicant">
                      <applicant-lead-form ref="applicantLeadForm"/>
                    </template>
                    <!-- TODO-DELETE 29/11/2022 el registro de inversionista se hace desde el onboarding -->
                    <!-- <template v-else>
                      <div class="vx-row mb-2" v-if="isInvestor">
                        <div class="vx-col w-full mb-3">
                          <p class="bold">¿Vas a invertir a tu nombre o a nombre de tu empresa?</p>
                        </div>
                        <div class="vx-col w-full mb-3">
                          <ul class="centerx radio-display">
                            <li class="mr-6">
                              <vs-radio v-model="accountType" vs-value="fisica">A mi nombre</vs-radio>
                            </li>
                            <li class="mr-6">
                              <vs-radio color="success" v-model="accountType" vs-value="moral">A nombre de mi empresa</vs-radio>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </template> -->

                    <div class="vx-row mb-2" v-if="isSupplier">
                      <div class="vx-col w-full mb-3">
                        <p class="bold">¿Tu negocio opera como persona física con actividad empresarial o como persona moral?</p>
                      </div>
                      <div class="vx-col w-full mb-3">
                        <ul class="centerx radio-display">
                          <li class="mr-6">
                            <vs-radio v-model="accountType" vs-value="fisica">Persona Física con Actividad Empresarial</vs-radio>
                          </li>
                          <li class="mr-6">
                            <vs-radio color="success" v-model="accountType" vs-value="moral">Persona Moral</vs-radio>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <template v-if="isMoral">
                      <div class="vx-row mb-4">
                        <div class="vx-col w-full">
                          <h5>Datos de la empresa / persona moral</h5>
                        </div>
                      </div>

                      <business-profile-form ref="businessProfile" :is-investor="isInvestor" />

                      <div class="vx-row mt-3 mb-4" v-if="isSupplier">
                        <div class="vx-col w-full">
                          <h5>Datos del representante/dueño del negocio</h5>
                        </div>
                      </div>
                      <div class="vx-row mt-3 mb-4" v-else>
                        <div class="vx-col w-full">
                          <h5>Datos del representante legal</h5>
                        </div>
                      </div>
                    </template>


                    <template v-if="accountTypeDefined">
                      <div class="vx-row mb-4" v-if="isPersonal">
                        <div class="vx-col w-full">
                          <h5>Tu información</h5>
                        </div>
                      </div>
                      <personal-profile-form ref="personalProfile" :is-supplier="isSupplier" :fixed-origin-country="isInvestor && isPersonal" />

                      <div class="vx-row mt-3 mb-4" v-if="isSupplier">
                        <div class="vx-col w-full">
                          <h5>Datos del negocio</h5>
                        </div>
                      </div>
                      <supplier-data-form ref="supplierForm" v-if="isSupplier" />

                      <div class="vx-row mt-3 mb-4">
                        <div class="vx-col w-full">
                          <h5>Datos para acceso</h5>
                        </div>
                      </div>
                      <user-data-form ref="userDataForm" />

                      <div class="vx-row mt-3 mb-3">
                        <div class="vx-col w-full mb-3">
                          <div class="checkbox-display">
                            <vs-checkbox
                              name="terms"
                              v-model.lazy="contracts.allContracts">
                            </vs-checkbox>
                            <p >Acepto los
                              <a class="inline-link-primary" target="_blank" rel="noopener" :href="$sanitizeUrl(terminosCondicionesUrl)"> Términos y Condiciones</a>
                              y el <a class="inline-link-primary" target="_blank" rel="noopener" :href="$sanitizeUrl(avisoPrivacidadUrl)">Aviso de Privacidad</a>.
                            </p>
                          </div>
                        </div>
                      </div>
                    </template>

                    <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mb-5" color="danger">
                      <span class="font-regular">{{errorMssg}}</span>
                    </vs-alert>

                    <div class="vx-row" v-if="accountTypeDefined && !isApplicant">
                      <div class="vx-col w-full">
                          <vs-button class="ml-auto mt-2" @click="saveRequest">Estoy list@</vs-button>
                      </div>
                    </div>
                    <div class="vx-row" v-if="isApplicant">
                      <div class="vx-col w-full">
                        <vs-button class="ml-auto mt-2" @click="saveApplicantRequest">Enviar datos</vs-button>
                      </div>
                    </div>

                    <div class="mt-base mb-3">
                      <vs-button type="line" color="dark" class="mb-1 p-1 link-btn" @click="goToSelectType()">
                        <!-- TODO-DELETE 29/11/2022 el registro de inversionista se hace desde el onboarding -->
                        <!-- <template v-if="isInvestor">
                          ¿Te quieres registrar con otro tipo de cuenta?
                        </template>
                        <template v-else>
                          ¿Te quieres registrar como inversionista?
                        </template> -->
                        ¿Te quieres registrar como inversionista?
                      </vs-button>
                    </div>

                  </div>
                  <!-- END MAIN FORM DIV -->

                  <!-- TODO-DELETE 29/11/2022 se valida en un nivel superior RegisterFromReferalPage -->
                  <!-- NOT VALID REFERAL MESSAGE -->
                  <!-- <div class="invalid-referal-code" v-if="isMounted && fromReferal && !validReferalCode">
                    <vs-alert class="h-full mb-3" color="danger">
                      El código de invitación <span class="bold">{{referalCode}}</span> no es válido.
                    </vs-alert>
                    <p>Por favor verifica el código que te compartieron o comunícate con nosotros a través del Chat.</p>
                    <div class="mt-base mb-3">
                      <vs-button type="line" color="dark" class="mb-1 p-1 link-btn" @click="goToRegisterNoCode()" >¿Deseas registrarte sin un código de Re(d)ferido?</vs-button>
                    </div>
                  </div> -->
                  <!-- END NOT VALID REFERAL MESSAGE -->
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>

      <vs-prompt
        :active.sync="activePrompt"
        title="¡Tu registro ha sido exitoso!"
        color="success"
        :buttonsHidden="true"
        type="confirm" >

        <div>
          <span v-if="isSupplier">
            Estás muy cerca de tener acceso al mejor crédito solar de México para tus clientes. <br><br>
          </span>
          <!-- TODO-DELETE 29/11/2022 el registro de inversionista se hace desde el onboarding -->
          <!-- <span v-else-if="isInvestor">
            ¡Ya estás muy cerca de comenzar a crecer tu dinero gracias a la energía limpia! <br><br>
          </span> -->
          <span class="bold">
            Te hemos enviado un correo electrónico para que actives tu cuenta y comiences tu proceso de verificación. <br><br>
          </span>
          <span>
            Si tienes alguna duda contáctanos a través del chat de la plataforma o en cualquiera de nuestras redes sociales.
          </span>
        </div>

        <footer>
          <vs-button
            color="success"
            class="vs-dialog-accept-button"
            @click="goToLogin">Finalizar</vs-button>
        </footer>
      </vs-prompt>

    </div>
  </div>
</template>

<script>
import PersonalProfileForm from "@components/register/PersonalProfileForm";
import BusinessProfileForm from "@components/register/BusinessProfileForm";
import UserDataForm from "@components/register/UserDataForm";
import SupplierDataForm from "@components/register/SupplierDataForm";
import ApplicantLeadForm from "@components/register/ApplicantLeadForm";
import InvestorOnboardingRegisterPage from "@views/investor/InvestorOnboardingRegisterPage";
import SupplierOnboardingRegisterPage from "@views/supplier/onboarding/SupplierOnboardingRegisterPage.vue";
import ApplicantOnboardingRegisterPage from "../applicant/onboarding/ApplicantOnboardingRegisterPage.vue";
const investorType = 'inversionista';
const supplierType = 'instalador';
const applicantType = 'solicitante';
export default {
  name: "RegisterFormPage",
  props: {
    type: {
      type: String
    },
    referalInfo: {
      type: Object,
      default: () => null
    }
  },
  components: {
    ApplicantLeadForm,
    PersonalProfileForm,
    BusinessProfileForm,
    SupplierDataForm,
    UserDataForm,
    InvestorOnboardingRegisterPage,
    SupplierOnboardingRegisterPage,
    ApplicantOnboardingRegisterPage
},
  async beforeMount(){
    this.isMounted = false
    this.showForm = false
    if(this.$props.type !== investorType && this.$props.type !== supplierType && this.$props.type !== applicantType){
      await this.$router.replace({name: 'infoRegistro'});
      return;
    }
    // TODO-DELETE 29/11/2022 se valida en un nivel superior RegisterFromReferalPage
    // if(this.$props.referalCode != null && this.$props.referalCode !== ""){
    //   this.fromReferal = true;
    //   this.showLoading(true)
    //   this.validReferalCode = await this.validateReferalCode();
    //   if(this.validReferalCode){
    //     this.showForm = true;
    //   }
    //   this.showLoading(false)
    // }
    // se valida que venga info del codigo de redferido
    if(this.$props.referalInfo != null){
      this.fromReferal = true;
      this.showForm = true;
    }
    else {
      this.showForm = true;
    }
    this.isMounted = true
  },
  data(){
    return {
      investorType: 'inversionista',
      supplierType: 'instalador',
      applicantType: 'solicitante',
      imgInvestorUrl: 'https://cdn.redgirasol.com/img/icons/A-02.png',
      accountType: null,
      supplierContractUrl: 'contrato-de-comision-mercantil-instalador',
      investorContractUrl: 'contrato-de-comision-mercantil-inversionista',
      applicantContractUrl: '',
      requiredRules: 'required',
      errorMssg: null,
      fromReferal: false,
      isMounted: false,
      showForm: false,
      // TODO-DELETE 29/11/2022 se valida en un nivel superior RegisterFromReferalPage
      // validReferalCode: false,
      contracts: {
        allContracts: null,
      },
      // TODO-DELETE 29/11/2022 se valida en un nivel superior RegisterFromReferalPage
      // referalInfo: {
      //   id: null,
      //   name: null,
      //   type: null,
      //   referal_type: null,
      // },
      activePrompt: false,
    }
  },
  watch: {
    accountType: function(o,n){
      this.onPersonTypeChanged();
    }
  },
  computed: {
    isPersonal(){
      return this.accountType === 'fisica';
    },
    isMoral(){
      return this.accountType === 'moral';
    },
    isInvestor(){
      return this.type === investorType;
    },
    isSupplier(){
      return this.type === supplierType;
    },
    isApplicant(){
      return this.type === applicantType;
    },
    accountTypeDefined(){
      return this.accountType !== null;
    },
    terminosCondicionesUrl(){
      return `${this.ApiDomain}/terminos-y-condiciones`;
    },
    avisoPrivacidadUrl(){
      return `${this.ApiDomain}/aviso-de-privacidad`;
    },
    comisionMercantilUrl(){
      if (this.type === investorType){
        return `${this.ApiDomain}/${this.investorContractUrl}`;
      }
      else if (this.type === supplierType){
        return `${this.ApiDomain}/${this.supplierContractUrl}`;
      }
      else {
        return `${this.ApiDomain}/${this.applicantContractUrl}`;
      }
    },
    referalCode(){
      return this.referalInfo? this.referalInfo.uri : null;
    }
  },
  methods: {
    // TODO-DELETE 29/11/2022 se valida en un nivel superior RegisterFromReferalPage
    // async validateReferalCode(){
    //   try {
    //     const res = await this.publicApiGet(`api/v2/preregister/validateReferalCode/${this.referalCode}`);
    //     this.referalInfo = res.data.referal;
    //     return true;
    //   }
    //   catch (e) {
    //     return false;
    //   }
    // },
    onPersonTypeChanged(){
      if(this.$refs.personalProfile){
        this.$refs.personalProfile.onPersonTypeChanged();
      }
    },
    async saveRequest(){
      this.errorMssg = null;
      const valid = await this.$refs.personalProfile.validateForm();
      const valid2 = await this.$refs.userDataForm.validateForm();
      const mvalid = await this.$validator.validateAll();
      let valid3 = true;
      if(this.isMoral){
        valid3 = await this.$refs.businessProfile.validateForm();
      }

      let complete = false;
      if(valid && valid2 && valid3 && mvalid){
        const mcontracts = this.validateContracts();
        if(mcontracts){
          complete = true;
        }
      }
      if(!complete) {
        this.errorNotif("Campos pendientes", "Por favor completa los campos pendientes para poder continuar.", 5000);
        return;
      }
      // TODO-DELETE 29/11/2022 el registro de inversionista se hace desde el onboarding
      // if(this.isInvestor && this.isPersonal){
      //   await this.doRegisterInvestorPF();
      // }
      // else if(this.isInvestor && this.isMoral){
      //   await this.doRegisterInvestorPM();
      // }
      if(this.isSupplier && this.isPersonal){
        await this.doRegisterSupplierPFAE();
      }
      else if(this.isSupplier && this.isMoral){
        await this.doRegisterSupplierPM();
      }
    },
    async saveApplicantRequest(){
      this.errorMssg = null;
      const valid = await this.$refs.applicantLeadForm.validateForm();
      if(!valid) {
        return;
      }
      await this.doRegisterApplicantLead();
    },
    // registro de lead de solicitante
    async doRegisterApplicantLead(){
      this.showLoading(true)
      try {
        let payload = {
          lead: this.$refs.applicantLeadForm.getModel(),
        }
        await this.publicApiPost('/api/v2/leads/registerApplicant', payload);
        this.$refs.applicantLeadForm.resetForm();
        let mssg = "Gracias por tu interés en formar parte de la Red.\n\nEn breve, uno de nuestros agentes " +
          "se pondrá en contacto contigo.";
        this.successDialogWithAcceptCallback(this.goToLogin, "Hemos recibido tus datos", mssg, 'Finalizar');
      }
      catch (e) {
        this.showError(e);
      }
      this.showLoading(false)
    },
    // TODO-DELETE 29/11/2022 el registro de inversionista se hace desde el onboarding
    // // registro inversionista PF
    // async doRegisterInvestorPF(){
    //   this.showLoading(true, 'Realizando tu registro...')
    //   try {
    //     let payload = {
    //       user: this.$refs.userDataForm.getModel(),
    //       personal: this.$refs.personalProfile.getModel(),
    //       from_referal: this.fromReferal,
    //       referal_code: this.fromReferal ? this.referalCode : null
    //     };

    //     await this.publicApiPost('/api/v2/preregister/investorPF', payload);
    //     this.showSuccess();
    //   }
    //   catch (e) {
    //     this.showError(e);
    //   }
    //   this.showLoading(false)
    // },
    // // registro inversionista PM
    // async doRegisterInvestorPM(){
    //   this.showLoading(true, 'Realizando tu registro...')
    //   try {
    //     let payload = {
    //       user: this.$refs.userDataForm.getModel(),
    //       personal: this.$refs.personalProfile.getModel(),
    //       business: this.$refs.businessProfile.getModel(),
    //       from_referal: this.fromReferal,
    //       referal_code: this.fromReferal ? this.referalCode : null
    //     };

    //     await this.publicApiPost('/api/v2/preregister/investorPM', payload);
    //     this.showSuccess();
    //   }
    //   catch (e) {
    //     this.showError(e);
    //   }
    //   this.showLoading(false)
    // },
    // registro instalador PF
    async doRegisterSupplierPFAE(){
      this.showLoading(true, 'Realizando tu registro...')
      try {
        let payload = {
          user: this.$refs.userDataForm.getModel(),
          personal: this.$refs.personalProfile.getModel(),
          supplier: this.$refs.supplierForm.getModel(),
          from_referal: this.fromReferal,
          referal_code: this.fromReferal ? this.referalCode : null
        };

        await this.publicApiPost('/api/v2/preregister/supplierPFAE', payload);
        this.showSuccess();
      }
      catch (e) {
        this.showError(e);
      }
      this.showLoading(false)
    },
    // registro instalador PM
    async doRegisterSupplierPM(){
      this.showLoading(true, 'Realizando tu registro...')
      try {
        let payload = {
          user: this.$refs.userDataForm.getModel(),
          personal: this.$refs.personalProfile.getModel(),
          business: this.$refs.businessProfile.getModel(),
          supplier: this.$refs.supplierForm.getModel(),
          from_referal: this.fromReferal,
          referal_code: this.fromReferal ? this.referalCode : null
        };

        await this.publicApiPost('/api/v2/preregister/supplierPM', payload);
        this.showSuccess();
      }
      catch (e) {
        this.showError(e);
      }
      this.showLoading(false)
    },
    showSuccess(){
      this.activePrompt = true;
    },
    showError(e){
      this.warn(e)
      let err = "Ha ocurrido un error con la operación, intente más tarde";
      if(e.response && e.response.data && e.response.data.error){
        err = e.response.data.error;
      }
      this.errorMssg = err;
      this.errorNotif("Error en el registro", err, 10000);
    },
    async goToSelectType(){
      await this.$router.replace({name: 'nuevoRegistro', params: {type: 'inversionista'}});
      // Descomentar y eliminar la linea de arriba si se requiere seleccionar el registro porque la ruta infoRegistro esta comentada
      // await this.$router.replace({name: 'infoRegistro'});
    },
    async goToLogin(){
      await this.$router.replace({name: 'login'});
    },
    async goToRegisterNoCode(){
      await this.$router.replace({name: 'nuevoRegistro', params: {type: 'inversionista'}});
    },
    validateContracts(){
      if(this.contracts.allContracts !== true){
        this.errorMssg = "Debes aceptar los Términos y Condiciones y el Aviso de Privacidad.";
        return false;
      }
      return true;
    },
    resetForm(){
    }
  }
}
</script>

<style lang="css" scoped>
.radio-display {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.checkbox-display {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
</style>
