<template>
  <div>
    <div class="vx-row">
      <div :class="colClass">
        <vs-input
          class="w-full"
          label="Razón social *"
          name="name"
          v-validate="requiredRules"
          v-model.lazy="model.name"
          :danger="hasError('name')"
          :danger-text="errorText('name')"
          :success="isSuccess('name')"
          icon-pack="feather"
          :placeholder="requiredPlaceholder"
          v-on="{input: e => {model.name = upper(e)}}"
        />
      </div>
      <div :class="shortColClass" v-if="isInvestor && isMounted">
        <vx-tooltip
          v-if="isInvestor"
          title="País de residencia"
          color="primary"
          position="left"
          style="float:right;cursor:pointer;margin-top:-8px"
          text="Por el momento sólo permitimos registro de personas morales que cuenten con RFC Mexicano y tengan una cuenta de banco en México.">
          <vs-chip color="primary">?</vs-chip>
        </vx-tooltip>
        <label class="vs-input--label">Nacionalidad *</label>
        <v-select
          class="vs-custom"
          name="nationality"
          :class="{'has-error': hasError('nationality'), 'is-success': isSuccess('nationality')}"
          v-model.lazy="model.nationality"
          v-validate="requiredRules"
          :options="collections.countries"
          :reduce="item => item.value"
          :clearable="false"
          placeholder="Selecciona una opción"
          :disabled="isInvestor"
          :searchable="false">
        </v-select>
        <v-error v-if="hasError('nationality')" :error="errorText('nationality')" />
      </div>
      <div :class="shortColClass" v-if="isInvestor && isMexCountry && isMounted">
        <vs-input
          class="w-full"
          label="RFC *"
          name="rfc"
          v-validate="rfcRules"
          v-model.lazy="model.rfc"
          :danger="hasError('rfc')"
          :danger-text="errorText('rfc')"
          :success="isSuccess('rfc')"
          icon-pack="feather"
          :placeholder="requiredPlaceholder"
          v-on="{input: e => {model.rfc = upper(e)}}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formHelper from "@mixins/formHelper";
export default {
  name: "BusinessProfileForm",
  mixins: [inputHelper, formHelper],
  props: {
    isInvestor: { type: Boolean, required: true },
  },
  data(){
    return {
      colClass: "vx-col xxl:w-1/2 lg:w-2/3 w-full mb-5",
      shortColClass: "vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5",
      requiredRules: 'required',
      optionalRules: '',
      requiredPlaceholder: '(Requerido)',
      optionalPlaceholder: '(Opcional)',
      rfcRules: 'required|length:12',
      model: {
        name: null,
        nationality: null,
        rfc: null,
      },
      collections: {
        countries: []
      },
      mexicoId: 700,
      isMounted: false,
    }
  },
  async beforeMount(){
    this.isMounted = false
    this.showLoading(true)
    await this.getCollections();
    this.model.nationality = this.mexicoId;
    this.showLoading(false)
    this.isMounted = true
  },
  computed: {
    isMexCountry(){
      return this.model.nationality === 700;
    }
  },
  methods: {
    async getCollections(){
      try {
        let collectionsObjects = ['countriesList'];
        let params = "with[]=" + collectionsObjects.join("&with[]=");
        let res = await this.publicApiGet(`/api/v1/forms/getPublicFormCollections?${params}`);
        let colls = res.data;
        colls.countriesList.forEach(opt => {
          this.collections.countries.push({value: parseInt(opt.id), label: opt.name}); //conversion a sintaxis de vx-select
        })
      }
      catch (e) {
      }
    },
    getModel(){
      return this.model;
    },
    async validateForm(){
      return await this.$validator.validateAll();
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);
    },
  }
}
</script>
