<template>
    <div id="1_signup_container" class="vx-row no-gutter justify-around p-10">
        <div class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-2/5 m-0">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <div class="vx-col sm:w-full">
                        <div class="pt-8 login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <div class="pt-5 pb-5">
                                        <!-- ENCABEZADO -->
                                        <div class="vx-row mb-4">
                                            <div class="vx-col w-full">
                                                <p class="text-4xl card-sub-title text-center mb-base">Creemos tu cuenta.</p>
                                                <h6 class="text-1xl card-sub-title text-center mb-4">¿Para quién es el crédito?</h6>
                                                <div id="has-supplier" v-if="showMessageInFront"  class="referral-code mt-4 mb-2">
                                                    Te estás registrando con la referencia del promotor / proveedor: <br>
                                                    <span class="bold"><u>{{ supplierReferralInfo.tradename }}</u></span>.
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="hasToSelectAccountType" class="mt-4">
                                            <div class="mb-4">                                                
                                                <div class="vx-row same-h pl-2 pr-2 mb-base">
                                                    <div @click.stop="accountType = item.value" v-for="(item, index) in accountTypeList" class="vx-col sm:w-full md:w-1/2 lg:w-1/2">
                                                        <vx-card class="mb-4" :key="index" :id="`option_${index}`" :class="accountType == item.value ? 'green-shadow' : ''">
                                                            <div class="relative h-auto" style="min-height: 16rem;">
                                                                <h5 class="mb-4">{{ item.label }}</h5>
                                                                <p class="text-sm black mb-4" v-html="item.desc"></p>
                                                                <ul class="text-sm black ul-disc ml-4">
                                                                    <li v-for="(product, pindex) in item.products" :key="pindex" class="bold"><small>{{ product }}</small></li>
                                                                </ul>
                                                                <div class="absolute bottom-0 right-0">
                                                                    <feather-icon
                                                                        :icon="accountType == item.value ? 'CheckCircleIcon' : 'CircleIcon'"
                                                                        svgClasses="w-5 h-5 m-1"
                                                                        :class="accountType == item.value ? 'bg-primary text-white' : 'dark'"
                                                                        class="rounded-full"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </vx-card>
                                                    </div>
                                                </div>

                                                <!-- <div class="vx-col w-full mb-8 account-button mt-2"
                                                    v-for="(item, index) in accountTypeList" :key="index">
                                                    <rg-radio class="w-full" groupName="accountType" :label="item.label"
                                                        :value="item.value" :desc="item.desc" v-model="accountType" />
                                                </div> -->

                                                <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle"
                                                    class="mb-5" color="danger">
                                                    <span class="font-regular">{{ errorMssg }}</span>
                                                </vs-alert>
                                            </div>

                                            <div class="flex flex justify-center mb-2">
                                                <vs-button :disabled="accountType == null" class="w-1/2 vs-button-dark"
                                                    @click="hasToSelectAccountType = false">Continuar</vs-button>
                                            </div>
                                            <h5 class="footer-register text-center mt-5 mb-4">
                                                ¿Ya tienes una cuenta? <span><router-link
                                                        class="bold ml-1 inline-link-primary text-dark primary" to=""
                                                        @click.native="goToLogin">Inicia sesión.</router-link></span>
                                            </h5>
                                        </div>
                                        <div v-else>
                                            <!-- REFERAL CODE MESSAGE -->
                                            <div v-if="fromReferal">
                                                <vs-alert class="mb-3" icon-pack="feather" icon="icon-info"
                                                    style="height:auto" color="rgb(41, 147, 138)">
                                                    Estás utilizando el código de invitación <span class="bold">{{
                                                        referalInfo.uri }}</span>.
                                                </vs-alert>
                                            </div>
                                            <!-- END REFERAL CODE MESSAGE -->
                                            <!-- CONTENIDO -->
                                            <div class="main-form px-10">
                                                <div class="vx-row">
                                                    <!-- <div :class="colClass">
                                                        <vs-input class="w-full" label="Nombre de usuario" name="alias"
                                                            v-validate="requiredRules" v-model.lazy="onboardingUserData.alias"
                                                            :danger="hasError('alias')"
                                                            danger-text="El campo es obligatorio. Escribe un alias o nombre de usuario con el que te gustaría ser 
                                            identificado en la plataforma, este lo podrás utilizar después para ingresar a RedGirasol"
                                                            :success="isSuccess('alias')" icon-pack="feather"
                                                            :placeholder="requiredPlaceholder" />
                                                        <vs-input-hint>El nombre de usuario puede contener letras y
                                                            números</vs-input-hint>
                                                    </div> -->
                                                    <div :class="colClass">
                                                        <vs-input class="w-full" label="Correo electrónico"
                                                            name="email" v-validate="emailRules"
                                                            v-model.lazy="onboardingUserData.email"
                                                            :danger="hasError('email')" :danger-text="errorText('email')"
                                                            :success="isSuccess('email')" icon-pack="feather"
                                                            :placeholder="emailPlaceholder" />
                                                    </div>
                                                    <div :class="colClass">
                                                        <label class="vs-input--label">Contraseña *</label>
                                                        <vx-input-group class="w-full">
                                                            <vs-input v-model.lazy="onboardingUserData.password"
                                                                v-validate="requiredRules" :danger="hasError('password')"
                                                                :danger-text="errorText('password')"
                                                                :success="passwordFieldValid" :type="passInputType"
                                                                :placeholder="requiredPlaceholder" name="password" />

                                                            <template slot="append">
                                                                <div class="append-text btn-addon" style="height:40px;">
                                                                    <vs-button color="dark" @click="togglePassInputType()"
                                                                        icon-pack="feather"
                                                                        :icon="passInputIcon"></vs-button>
                                                                </div>
                                                            </template>
                                                        </vx-input-group>
                                                    </div>
                                                    <div v-if="onboardingUserData.password != null && onboardingUserData.password != ''"
                                                        :class="colClass">
                                                        <div class="vx-row">
                                                            <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                                <p>
                                                                    <feather-icon
                                                                        :svgClasses="`${colorForCriteria(0)} h-4 w-4`"
                                                                        class="p-2" :icon="iconForCriteria(0)" />Una letra
                                                                    minúscula <br>
                                                                    <feather-icon
                                                                        :svgClasses="`${colorForCriteria(1)} h-4 w-4`"
                                                                        class="p-2" :icon="iconForCriteria(1)" />Una letra
                                                                    mayúscula <br>
                                                                    <feather-icon
                                                                        :svgClasses="`${colorForCriteria(2)} h-4 w-4`"
                                                                        class="p-2" :icon="iconForCriteria(2)" />Un número
                                                                    <br>
                                                                    <feather-icon
                                                                        :svgClasses="`${colorForCriteria(3)} h-4 w-4`"
                                                                        class="p-2" :icon="iconForCriteria(3)" />Un símbolo
                                                                    <br>
                                                                </p>
                                                            </div>
                                                            <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                                <p>
                                                                    <feather-icon
                                                                        :svgClasses="`${colorForCriteria(4)} h-4 w-4`"
                                                                        class="p-2" :icon="iconForCriteria(4)" />8
                                                                    caracteres
                                                                    mínimo <br>
                                                                    <feather-icon
                                                                        :svgClasses="`${colorForCriteria(5)} h-4 w-4`"
                                                                        class="p-2" :icon="iconForCriteria(5)" />Sin letras
                                                                    ni
                                                                    números consecutivos <br>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div :class="colClass">
                                                        <label class="vs-input--label">Confirma la contraseña *</label>
                                                        <vx-input-group class="w-full">
                                                            <vs-input onpaste="return false;"
                                                                v-model.lazy="onboardingUserData.confirmed_password"
                                                                v-validate="requiredRules"
                                                                :danger="hasError('confirmed_password')"
                                                                :danger-text="errorText('confirmed_password')"
                                                                :success="confirmPasswordFieldValid"
                                                                :type="confirmPassInputType"
                                                                :placeholder="requiredPlaceholder"
                                                                name="confirmed_password" />
                                                            <template slot="append">
                                                                <div class="append-text btn-addon" style="height:40px;">
                                                                    <vs-button color="dark"
                                                                        @click="toggleConfirmPassInputType()"
                                                                        icon-pack="feather"
                                                                        :icon="confirmPassInputIcon"></vs-button>
                                                                </div>
                                                            </template>
                                                        </vx-input-group>
                                                    </div>
                                                    <div :class="colClass">
                                                        <label class="vs-input--label">Celular a 10 dígitos</label>
                                                        <div class="flex">
                                                            <vue-country-code name="dialCode" class="vs-custom"
                                                                data-vv-name="dialCode" :enableSearchField="true"
                                                                searchPlaceholderText="Buscar código de país"
                                                                :enabledCountryCode="true" @onSelect="onSelect"
                                                                :preferredCountries="['MX']" :danger="hasError('dialCode')"
                                                                :danger-text="errorText('dialCode')"
                                                                :success="isSuccess('dialCode')">
                                                            </vue-country-code>
                                                            <vs-input class="w-full" label="" name="phone" type="tel"
                                                                v-validate="'required|numeric'"
                                                                v-model.lazy="onboardingPhoneData.phone"
                                                                :danger="hasError('phone')"
                                                                :danger-text="errorText('phone')"
                                                                :success="isSuccess('phone')" icon-pack="feather"
                                                                placeholder="(Requerido) Ingresa 10 dígitos" />
                                                        </div>
                                                    </div>
                                                    <div :class="colClass">
                                                        <div class="vx-col w-full mb-2">
                                                            <label class="vs-input--label">¿Cómo te enteraste de
                                                                RedGirasol?</label>
                                                            <vs-select label="" name="source" v-validate="requiredRules"
                                                                :danger="hasError('source')"
                                                                :danger-text="errorText('source')"
                                                                :success="isSuccess('source')"
                                                                placeholder="Selecciona una opción."
                                                                v-model.lazy="onboardingUserData.source"
                                                                class="mr-2 w-full">
                                                                <vs-select-item v-for="(item, index) in sourceOptions"
                                                                    :text="item.label" :key="index"
                                                                    :value="item.value"></vs-select-item>
                                                            </vs-select>
                                                        </div>
                                                        <div :class="colClass" v-if="onboardingUserData.source === 'other'">
                                                            <vs-input class="w-full" label="" name="other_source"
                                                                v-validate="requiredRules"
                                                                v-model.lazy="onboardingUserData.other_source"
                                                                :danger="hasError('other_source')"
                                                                :danger-text="errorText('other_source')"
                                                                :success="isSuccess('other_source')" icon-pack="feather"
                                                                placeholder="¿Cómo?" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle"
                                                    class="mb-5" color="danger">
                                                    <span class="font-regular">{{ errorMssg }}</span>
                                                </vs-alert>
                                                <div class="vx-row">
                                                    <div class="footer-register vx-col w-full">
                                                        <div class="flex mb-4">
                                                            <!-- <vs-checkbox v-model="onboardingUserData.check_legal"
                                                                name="legal" v-validate="requiredRules"
                                                                :danger="hasError('legal')"
                                                                :danger-text="errorText('legal')"
                                                                :success="isSuccess('legal')"></vs-checkbox> -->
                                                            <p>
                                                                Al dar click en "Crear cuenta", aceptas los <a class="inline-link" target="_blank"
                                                                    rel="noopener"
                                                                    :href="$sanitizeUrl(terminosCondicionesUrl)"> Términos y
                                                                    Condiciones</a> y el <a class="inline-link"
                                                                    target="_blank" rel="noopener"
                                                                    :href="$sanitizeUrl(avisoPrivacidadUrl)">Aviso de
                                                                    Privacidad</a> de RedGirasol.
                                                            </p>
                                                        </div>
                                                        <vs-button id="1_signup_confirm_btn" size="large"
                                                            class="w-full ml-auto mt-3 mb-2 vs-button-dark"
                                                            @click="saveOnboardingInitalStep(true)">Crear cuenta</vs-button>
                                                        <h5 class="text-center mt-5">
                                                            ¿Ya tienes una cuenta? <span><router-link
                                                                    class="bold ml-1 inline-link-primary text-dark" to=""
                                                                    @click.native="goToLogin">Inicia
                                                                    sesión.</router-link></span>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- END MAIN FORM DIV -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
            <SupplierReferralCode v-if="isMounted && showCodeInput" @onupdated="setSupplierInfo" :need-user="false" :code-prop="code" />
        </div>
    </div>
</template>
  
<script>
import { mapActions, mapMutations } from 'vuex'
import sessionsHelper from "@mixins/sessionsHelper";
import inputHelper from "@mixins/inputHelper";
import VsInputHint from '@components/vsInput/VsInputHint.vue';
import SupplierReferralCode from '../../../components/supplier/widgets/SupplierReferralCode.vue';
import formatHelper from "@mixins/formatHelper";
const textType = "text";
const iconEye = "icon-eye";
const iconEyeOff = "icon-eye-off";

const accountTypes = [
    { 
        label: "Persona física", 
        value: "PF", 
        desc: "Facturas a tu nombre, no tienes negocio propio. </br> Buscas un crédito personal de hasta ",
        products: [
            "Crédito Solar",
            "Crédito PYME (con actividad empresarial)",
            "Crédito Personal",
            "Crédito Automotriz"
        ]
    },
    //{ label: "Persona física con actividad empresarial", value: "PFAE", desc: "Tienes un negocio pero facturas a tu nombre. Crédito de " },
    { 
        label: "Persona moral", 
        value: "PM", 
        desc: "Buscas un crédito para tu empresa de hasta ",
        products: [
            "Crédito Solar",
            "Crédito PYME",
            "Crédito Automotriz"
        ]
    }
];

const sourceOptions = [
    { label: "Me platicó un amigo/conocido/familiar", value: 'familiar' },
    { label: "Google u otro motor de búsqueda", value: 'search_engine' },
    { label: "Redes sociales (Facebook, Youtube, Twitter, etc.)", value: 'social_networks' },
    { label: "Nota en periódico/revista/blog", value: 'publication' },
    { label: "Referido por promotor", value: 'promotor' },
    { label: "Simulación portal publico", value: 'simulation' },
    { label: "Otro", value: 'other' },
]

export default {
    name: "ApplicantOnboardingRegisterPage",
    props: {
        referalInfo: {
            type: Object,
            default: () => null
        },
        type: {
            type: String
        },
    },
    mixins: [inputHelper, sessionsHelper, formatHelper],
    components: {
        VsInputHint,
        SupplierReferralCode
    },
    data() {
        return {
            // imgInvestorUrl: 'https://cdn.redgirasol.com/img/icons/A-02.png',
            // investorContractUrl: 'contrato-de-comision-mercantil-inversionista',
            requiredRules: 'required',
            errorMssg: null,
            contracts: {
                allContracts: null,
            },
            colClass: "vx-col w-full mb-5",
            requiredPlaceholder: '(Requerido)',
            emailRules: 'required|email',
            emailPlaceholder: 'ejemplo@mail.com',
            passInputPassMode: true,
            confirmPassInputPassMode: true,
            criteria: [false, false, false, false, false, false, false],
            passwordError: null,
            onboardingUserData: {
                alias: null,
                email: null,
                password: null,
                confirmed_password: null,
                source: null,
                other_source: null,
                check_legal: false,
            },
            onboardingPhoneData: {
                country_calling_code: null,
                phone: null,
            },
            accountType: null,
            payload: {},
            hasToSelectAccountType: true,
            accountTypeList: accountTypes,
            sourceOptions: sourceOptions,
            supplierReferralInfo: null,
            code: null,
            showMessageInFront: false,
            loanLimits: null,
            isMounted: false
        }
    },
    async beforeMount() {
        this.showLoading(true);
        this.isMounted = false;
        await this.getLoanLimits();
        this.validateReferredCode();
        this.validateSimulationOrigin();
        this.isMounted = true;
        this.showLoading(false);
    },
    computed: {
        terminosCondicionesUrl() {
            return `${this.ApiDomain}/terminos-y-condiciones`;
        },
        avisoPrivacidadUrl() {
            return `${this.ApiDomain}/aviso-de-privacidad`;
        },
        // comisionMercantilUrl() {
        //     return `${this.ApiDomain}/${this.investorContractUrl}`;
        // },
        passInputType() {
            return this.passInputPassMode ? "password" : textType;
        },
        passInputIcon() {
            return this.passInputPassMode ? iconEye : iconEyeOff;
        },
        confirmPassInputType() {
            return this.confirmPassInputPassMode ? "password" : textType;
        },
        confirmPassInputIcon() {
            return this.confirmPassInputPassMode ? iconEye : iconEyeOff;
        },
        passwordFieldValid() {
            return this.testPassword(this.onboardingUserData.password);
        },
        confirmPasswordFieldValid() {
            return this.onboardingUserData.password === this.onboardingUserData.confirmed_password && this.onboardingUserData.confirmed_password != null;
        },
        fromReferal() {
            return this.referalInfo ? true : false;
        },
        referalCode() {
            return this.referalInfo ? this.referalInfo.uri : null;
        },
        hasReferralCode() {
            return this.supplierReferralInfo != null;
        },
        showCodeInput() {
            if(this.code == null) {
                return true;
            } else if (this.code != null && !this.showMessageInFront) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        ...mapActions('auth', ['getUserInfo']),
        ...mapMutations('auth', ['setStateAccessToken']),
        ...mapMutations('extended_auth', ['set_new_extended_session', 'set_extended_session_token', 'set_extended_session_role', 'set_extended_session_user']),
        onSelect({ dialCode }) {
            this.onboardingPhoneData.country_calling_code = `+${dialCode}`;
        },
        // SE GUARDA EL STEP INICIAL
        async saveOnboardingInitalStep(isReady = false) {
            this.errorMssg = null;
            if (!await this.validateForm()) {
                return;
            }

            // if (this.onboardingUserData.check_legal === false) {
            //     this.errorMssg = "Debes aceptar los términos y condiciones y el aviso de privacidad para continuar.";
            //     return;
            // }

            this.onboardingUserData.check_legal = true;

            try {
                this.payload = {
                    user: this.onboardingUserData,
                    phone_data: this.onboardingPhoneData,
                    referral_info: this.supplierReferralInfo,
                };
                
                if (isReady) {
                    if (this.accountType != null) {
                        this.showLoading(true, 'Realizando tu registro...')
                        this.payload.person_type = this.accountType;
                        await this.publicApiPost('/api/v2/applicantOnboarding/new', this.payload);
                        this.showLoading(false);
                        await this.doLogin();
                    } else {
                        this.errorMssg = "Para continuar, selecciona la opción que mejor describa tu préstamo."
                        // Mandamos a seleccionar su tipo de persona. 
                        this.hasToSelectAccountType = true;
                    }
                } else {
                    // Mandamos a seleccionar su tipo de persona. 
                    this.hasToSelectAccountType = true;
                }


            }
            catch (e) {
                this.showError(e);
                this.showLoading(false);
            }
        },
        showError(e) {
            this.warn(e)
            let err = "Ha ocurrido un error con la operación, intente más tarde";
            if (e.response && e.response.data && e.response.data.error) {
                err = e.response.data.error;
            }
            this.errorMssg = err;
            this.errorNotif("Error en el registro", err, 10000);
        },
        async goToLogin() {
            await this.$router.replace({ name: 'login' });
        },
        async validateForm() {
            this.errorMssg = null;
            let r1 = await this.$validator.validateAll();
            if (r1) {
                return this.validatePassword();
            }
            return false;
        },
        colorForCriteria(idx) {
            return this.criteria[idx] ? 'text-success' : 'text-danger';
        },
        iconForCriteria(idx) {
            return this.criteria[idx] ? 'CheckCircleIcon' : 'CircleIcon';
        },
        completePasswordForm() {
            return this.onboardingUserData.password != null && this.onboardingUserData.password.length >= 8;
        },
        completeConfirmPassword() {
            return this.onboardingUserData.confirmed_password != null && this.onboardingUserData.confirmed_password.length >= 8;
        },
        validatePassword() {
            if (this.onboardingUserData.password !== this.onboardingUserData.confirmed_password) {
                this.errorMssg = "Las contraseñas no coinciden";
            }
            else {
                if (this.testPassword(this.onboardingUserData.password)) {
                    return true;
                }
                else {
                    this.errorMssg = "La contraseña no cumple los requerimientos";
                }
            }
            return false;
        },
        testPassword(pass) {
            if (pass === null || pass === undefined) {
                this.criteria[0] = false;
                this.criteria[1] = false;
                this.criteria[2] = false;
                this.criteria[3] = false;
                this.criteria[4] = false;
                this.criteria[5] = false;
                this.criteria[6] = false;
                return false;
            }
            //let pass = this.pw1
            const number = /.*[0-9].*/
            const lower = /.*[a-z].*/
            const upper = /.*[A-Z].*/
            const special = /.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~].*/
            const consecutive = ['abcd', 'bcde', 'cdef', 'defg', 'efgh', 'fghi', 'ghij', 'hijk', 'ijkl', 'jklm', 'klmn', 'lmnñ', 'mnño', 'nñop', 'ñopq', 'opqr', 'pqrs', 'qrst', 'rstu', 'stuv', 'tuvw', 'uvwx', 'vwxy', 'wxyz',
                '0123', '1234', '2345', '3456', '4567', '5678', '6789', '7890', '8901', '9012', '2109', '1098', '0987', '9876', '8765', '7654', '6543', '5432', '4321', '3210',
                'aaaa', 'bbbb', 'cccc', 'dddd', 'eeee', 'ffff', 'gggg', 'hhhh', 'iiii', 'jjjj', 'kkkk', 'llll', 'mmmm', 'nnnn', 'ññññ', 'oooo', 'pppp', 'qqqq', 'rrrr', 'ssss', 'tttt', 'uuuu', 'vvvv', 'wwww', 'xxxx', 'yyyy', 'zzzz',
                '1111', '2222', '3333', '5555', '6666', '7777', '8888', '9999',
                '!!!!', '""""', '####', '$$$$', '%%%%', '&&&&', '((((', '))))', '****', '++++', ',,,,', '----', '....', '////', '::::', ';;;;', '<<<<', '====', '>>>>',
                '????', '@@@@', '[[[[', ']]]]', '^^^^', '____', '````', '{{{{', '||||', '}}}}', '~~~~']

            this.criteria[0] = lower.test(pass);
            this.criteria[1] = upper.test(pass);
            this.criteria[2] = number.test(pass);
            this.criteria[3] = special.test(pass);
            this.criteria[4] = pass.length > 7;
            let no_consecutive = true
            for (let i = 0; i < consecutive.length; i++) {
                if (pass.toLowerCase().includes(consecutive[i])) {
                    no_consecutive = false
                }
            }
            this.criteria[5] = no_consecutive

            return (this.criteria[0] && this.criteria[1] && this.criteria[2] && this.criteria[3] && this.criteria[4] && this.criteria[5]);
        },
        togglePassInputType() {
            this.passInputPassMode = !this.passInputPassMode;
        },
        toggleConfirmPassInputType() {
            this.confirmPassInputPassMode = !this.confirmPassInputPassMode;
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return (ff && ff.required && ff.valid);
        },
        async doLogin() {
            this.showLoading(true);
            try {
                const payload = {
                  email: this.onboardingUserData.email,
                  password: this.onboardingUserData.password,
                  roleName: "investor",
                  emailRemember: false,
                  rememberme: false,
                  device_id: this.$cookies.get('device_id'),
                  twoFactorCode: ""
                };
                const res = await this.publicApiPost('api/auth/login', payload);
                this.$mixpanel.track('login', {
                    email: this.email
                })
                const token = res.data.access_token;
                const loggerRoleName = res.data.logged_role_name;
                // almacenar el token en auth (sessionStorage)
                this.setStateAccessToken(token);

                window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                this.$store.state.isUnauthenticated = false;
                const userRes = await this.getUserInfo(loggerRoleName);
                // verificar si debe almacenarse la sesion extendida
                this.checkForExtendedSession(token, userRes.data);
                this.showFormPrompt = false;

                // redirigir a la vista original a la que el usuario intento entrar
                if (this.$route.query && this.$route.query.redirect) {
                    const rep = this.$route.query.redirect;
                    await this.$router.replace(rep || '/');
                }
                else {
                    await this.$router.replace({ name: 'home' });
                }
            }
            catch (error) {
                this.onError = true;
                console.log(error)
                if (error && error.response && error.response.data && error.response.data.message) {
                    this.errorMssg = error.response.data.message;

                    if (error.response.data.locked) {
                        this.isLocked = (error.response.data.locked === "true" || error.response.data.locked === true);
                    }
                    if (error.response.data.extra && error.response.data.extra === this.ACCOUNT_NOT_ACTIVATED) {
                        this.canRequestNewActivationMail = true;
                    }
                }
                else {
                    this.errorMssg = "Ha ocurrido un error. Intenta por favor más tarde.";
                }
                this.warn(error);
            }
            this.showLoading(false);
        },
        checkForExtendedSession(token, user) {
            if (this.roleShouldHaveExtendedSession(user.role)) {
                // almacenar el token y los datos de la sesion en extended_auth (localStorage)
                this.set_new_extended_session(user.user_id);
                this.set_extended_session_token(token);
                this.set_extended_session_role(user.role);
                this.set_extended_session_user(user);
            }
        },
        async validateReferredCode() {
            try {
                const token = this.$route.query.r;
                if(token != undefined) {
                    this.code = token;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async validateSimulationOrigin() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const simulationValue = urlParams.get('simulation');

            if(simulationValue != null && simulationValue == "true") {
                this.onboardingUserData.source = 'simulation';
            }
        },
        setSupplierInfo(d) {
            // console.log(d);
            this.supplierReferralInfo = d;
            this.showMessageInFront = true;
        },
        async getLoanLimits() {
            try {
                const res = await this.publicApiGet(`api/v1/forms/getLoanLimits`);
                this.loanLimits = res.data;
                this.accountTypeList[0].desc = `Desde <strong>${this.explicitMoneyFormat(this.loanLimits.min_limit, 0)}</strong> 
                                                hasta <strong>${this.explicitMoneyFormat(this.loanLimits.max_limit_pf, 0)},</strong>
                                                o hasta <strong>${this.explicitMoneyFormat(this.loanLimits.max_limit_pm, 0)}</strong> si tienes actividad empresarial`

                // this.accountTypeList[1].desc += `<strong>${this.explicitMoneyFormat(this.loanLimits.min_limit)}</strong> hasta <strong>${this.explicitMoneyFormat(this.loanLimits.max_limit_pm)}</strong>`
                this.accountTypeList[1].desc = `Desde <strong>${this.explicitMoneyFormat(this.loanLimits.min_limit, 0)}</strong> 
                                                hasta <strong>${this.explicitMoneyFormat(this.loanLimits.max_limit_pm, 0)}</strong>`
            } catch (error) {
                console.log(error);
            }
        },
        handleOptionSelected(value) {
            this.accountType = value;
        }
    }
}
</script>
<style>
.footer-prompt {
    padding: 1rem 0 0 0 !important;
}

.footer-register a {
    color: #28DE18 !important;
}

.account-type-selector {
    margin-left: 10%;
    margin-right: 10%;
}

@media (max-width: 1000px) {
    #offset-register {
        display: none;
    }
}

label.vs-input--label {
    font-weight: bold !important;
}

.card-sub-title {
    line-height: 1;
    margin-bottom: 1rem;
}


#has-supplier {
    background-color: #a7f805;
    padding: 1rem;
    margin: 3rem;

}
</style>