<template>
  <div>
    <div class="vx-row">
      <div :class="colClass">
        <vx-tooltip
          v-if="fixedOriginCountry"
          title="País de residencia"
          color="primary"
          position="left"
          style="float:right;cursor:pointer;margin-top:-8px"
          text="Por el momento sólo permitimos registro de inversionistas que cuenten con CURP Mexicano y tengan una cuenta de banco en México.">
          <vs-chip color="primary">?</vs-chip>
        </vx-tooltip>
        <label class="vs-input--label">País de residencia *</label>
        <v-select
          class="vs-custom"
          name="country_id"
          :class="{'has-error': hasError('country_id'), 'is-success': isSuccess('country_id')}"
          v-model.lazy="model.country_id"
          v-validate="requiredRules"
          :options="collections.countries"
          :reduce="item => item.value"
          :clearable="false"
          placeholder="Selecciona una opción"
          :disabled="isSupplier || fixedOriginCountry"
          :searchable="false">
        </v-select>
        <v-error v-if="hasError('country_id')" :error="errorText('country_id')" />
      </div>
      <div :class="colClass">
        <label class="vs-input--label">País de nacimiento *</label>
        <v-select
          class="vs-custom"
          name="origin_country"
          :class="{'has-error': hasError('origin_country'), 'is-success': isSuccess('origin_country')}"
          v-model.lazy="model.origin_country"
          v-validate="requiredRules"
          :options="collections.countries"
          :reduce="item => item.value"
          :clearable="false"
          placeholder="Selecciona una opción"
          :searchable="false">
        </v-select>
        <v-error v-if="hasError('origin_country')" :error="errorText('origin_country')" />
      </div>
      <div :class="colClass">
        <label class="vs-input--label">Fecha de nacimiento *</label>
        <datepicker
          class="w-full"
          v-model.lazy="model.birth_date"
          name="birth_date"
          :language="lang_es"
          v-validate="requiredRules"
          :danger="hasError('birth_date')"
          :danger-text="errorText('birth_date')"
          :success="isSuccess('birth_date')"
          format="dd/MM/yyyy"
          initial-view="year"
          :disabled-dates="disabledDates"
          :placeholder="requiredPlaceholder"
          :use-utc="true" />
        <v-error v-if="hasError('birth_date')" :error="errorText('birth_date')" />
      </div>
      <div :class="colClass">
        <label class="vs-input--label">Género *</label>
        <v-select
          name="gender"
          class="vs-custom"
          :class="{'has-error': hasError('gender'), 'is-success': isSuccess('gender')}"
          v-model.lazy="model.gender"
          v-validate="requiredRules"
          :options="genderOptions"
          :reduce="item => item.value"
          :clearable="false"
          placeholder="Selecciona una opción"
          :searchable="false">
        </v-select>
        <v-error v-if="hasError('gender')" :error="errorText('gender')" />
      </div>
      <div :class="colClass">
        <vs-input
          class="w-full"
          label="Nombre *"
          name="first_name"
          v-validate="requiredRules"
          v-model.lazy="model.first_name"
          :danger="hasError('first_name')"
          :danger-text="errorText('first_name')"
          :success="isSuccess('first_name')"
          icon-pack="feather"
          :placeholder="requiredPlaceholder"
          v-on="{input: e => {model.first_name = upper(e)}}"
        />
      </div>
      <div :class="colClass">
        <vs-input
          class="w-full"
          label="Segundo nombre"
          name="second_name"
          v-validate="optionalRules"
          v-model.lazy="model.second_name"
          :danger="hasError('second_name')"
          :danger-text="errorText('second_name')"
          :success="isSuccess('second_name')"
          icon-pack="feather"
          :placeholder="optionalPlaceholder"
          v-on="{input: e => {model.second_name = upper(e)}}"
        />
      </div>
      <div :class="colClass">
        <vs-input
          class="w-full"
          label="Apellido paterno *"
          name="last_name_1"
          v-validate="requiredRules"
          v-model.lazy="model.last_name_1"
          :danger="hasError('last_name_1')"
          :danger-text="errorText('last_name_1')"
          :success="isSuccess('last_name_1')"
          icon-pack="feather"
          :placeholder="requiredPlaceholder"
          v-on="{input: e => {model.last_name_1 = upper(e)}}"
        />
      </div>
      <div :class="colClass">
        <vs-input
          class="w-full"
          :label="isMexCountry ? 'Apellido materno *': 'Apellido materno'"
          name="last_name_2"
          :v-validate="isMexCountry ? requiredRules : optionalRules"
          v-model.lazy="model.last_name_2"
          :danger="hasError('last_name_2')"
          :danger-text="errorText('last_name_2')"
          :success="isSuccess('last_name_2')"
          icon-pack="feather"
          :placeholder="isMexCountry ? requiredPlaceholder : optionalPlaceholder"
          v-on="{input: e => {model.last_name_2 = upper(e)}}"
        />
      </div>
      <div v-if="isSupplier" :class="colClass">
        <div class="flex">
          <vue-country-code
            name="dialCode"
            class="vs-custom dial-code"
            data-vv-name="dialCode"
            :enableSearchField="true"
            searchPlaceholderText="Buscar código de país"
            :enabledCountryCode="true"
            @onSelect="onSelect"
            :defaultCountry="preferredCountries"
            :preferredCountries="['MX']"
            :danger="hasError('dialCode')"
            :danger-text="errorText('dialCode')"
            :success="isSuccess('dialCode')">
          </vue-country-code>
          <vs-input
            class="w-full"
            label="Teléfono a 10 dígitos *"
            name="phone"
            type="tel"
            v-validate="phoneRules"
            v-model.lazy="model.phone"
            :danger="hasError('phone')"
            :danger-text="errorText('phone')"
            :success="isSuccess('phone')"
            icon-pack="feather"
            :placeholder="phonePlaceholder"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import dateHelper from "@mixins/dateHelper";
import { es } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
export default {
  name: "PersonalProfileForm",
  mixins: [inputHelper, dateHelper],
  components: {
    Datepicker
  },
  props: {
    isSupplier: { type: Boolean, required: true },
    fixedOriginCountry: { type: Boolean, required: true },
  },
  data(){
    return {
      colClass: "vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5",
      requiredRules: 'required',
      optionalRules: '',
      requiredPlaceholder: '(Requerido)',
      optionalPlaceholder: '(Opcional)',
      phonePlaceholder: '(Requerido) Ingresa 10 dígitos',
      phoneRules: 'required|digits:10',
      genderOptions: [
        {"value": 1, "label": "HOMBRE"},
        {"value": 2, "label": "MUJER"}
      ],
      lang_es: es,
      model: {
        first_name: null,
        second_name: null,
        last_name_1: null,
        last_name_2: null,
        gender: null,
        birth_date: null,
        country_id: null,
        origin_country: null,
        phone: null,
        country_calling_code: null
      },

      preferredCountries: 'MX',

      collections: {
        countries: []
      },
      mexicoId: 700,
      isMounted: false,
      disabledDates: {
        from: new Date(),
      }
    }
  },
  async beforeMount(){
    this.isMounted = false
    this.showLoading(true)
    await this.getCollections();
    this.model.country_id = this.mexicoId;
    this.model.origin_country = this.mexicoId;
    this.setDefaultDate();
    this.showLoading(false)
    this.isMounted = true
  },
  computed: {
    isMexCountry(){
      return this.model.country_id === 700;
    }
  },
  methods: {
    onSelect({ dialCode }) {
      this.model.country_calling_code = `+${dialCode}`;
    },
    onPersonTypeChanged(){
      this.model.country_id = this.mexicoId;
      this.model.origin_country = this.mexicoId;
    },
    setDefaultDate(){
      let validDate = new Date(this.minDateForValidAge(18));
      this.disabledDates.from = validDate;
      this.model.birth_date = validDate;
    },
    getModel(){
      if(this.fixedOriginCountry){
        this.model.country_id = this.mexicoId;
      }
      return this.model;
    },
    async validateForm(){
      return await this.$validator.validateAll();
    },
    async getCollections(){
      try {
        let collectionsObjects = ['countriesList'];
        let params = "with[]=" + collectionsObjects.join("&with[]=");
        let res = await this.publicApiGet(`/api/v1/forms/getPublicFormCollections?${params}`);
        let colls = res.data;
        colls.countriesList.forEach(opt => {
          this.collections.countries.push({value: parseInt(opt.id), label: opt.name}); //conversion a sintaxis de vx-select
        })
      }
      catch (e) {
      }
    },
    validate_birthdate(age){
      let years = this.calculateAgeFromDate(this.model.birth_date);
      return years >= age;
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);
    },
  }
}
</script>
