<template>
  <div>
    <div class="vx-row">
      <div :class="colClass">
        <vs-input
          class="w-full"
          label="Alias *"
          name="alias"
          v-validate="requiredRules"
          v-model.lazy="model.alias"
          :danger="hasError('alias')"
          :danger-text="errorText('alias')"
          :success="isSuccess('alias')"
          icon-pack="feather"
          :placeholder="requiredPlaceholder"
        />
      </div>
      <div :class="colClass">
        <vs-input
          class="w-full"
          label="Correo electrónico *"
          name="email"
          v-validate="emailRules"
          v-model.lazy="model.email"
          :danger="hasError('email')"
          :danger-text="errorText('email')"
          :success="isSuccess('email')"
          icon-pack="feather"
          :placeholder="emailPlaceholder"
        />
      </div>
    </div>
    <div class="vx-row">
      <div :class="colClass">
        <label class="vs-input--label">Contraseña *</label>
        <vx-input-group class="w-full">
          <vs-input
            v-model.lazy="model.password"
            v-validate="requiredRules"
            :danger="hasError('password')"
            :danger-text="errorText('password')"
            :success="passwordFieldValid"
            :type="passInputType"
            :placeholder="requiredPlaceholder"
            name="password"/>

          <template slot="append">
            <div class="append-text btn-addon" style="height:40px;">
              <vs-button color="dark" @click="togglePassInputType()" icon-pack="feather" :icon="passInputIcon"></vs-button>
            </div>
          </template>
        </vx-input-group>
      </div>
      <div :class="colClass">
        <label class="vs-input--label">Confirma la contraseña *</label>
        <vx-input-group class="w-full">
          <vs-input
            onpaste="return false;"
            v-model.lazy="model.confirmed_password"
            v-validate="requiredRules"
            :danger="hasError('confirmed_password')"
            :danger-text="errorText('confirmed_password')"
            :success="confirmPasswordFieldValid"
            :type="confirmPassInputType"
            :placeholder="requiredPlaceholder"
            name="confirmed_password"/>

          <template slot="append">
            <div class="append-text btn-addon" style="height:40px;">
              <vs-button color="dark" @click="toggleConfirmPassInputType()" icon-pack="feather" :icon="confirmPassInputIcon"></vs-button>
            </div>
          </template>
        </vx-input-group>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <p class="mb-3">
          <strong>La contraseña debe contener:</strong>
        </p>
      </div>
      <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
        <p class="mb-5">
          <feather-icon :svgClasses="`${colorForCriteria(0)} h-4 w-4`" class="p-2" :icon="iconForCriteria(0)" />Una letra minúscula <br>
          <feather-icon :svgClasses="`${colorForCriteria(1)} h-4 w-4`" class="p-2" :icon="iconForCriteria(1)" />Una letra mayúscula <br>
          <feather-icon :svgClasses="`${colorForCriteria(2)} h-4 w-4`" class="p-2" :icon="iconForCriteria(2)" />Un número <br>
        </p>
      </div>
      <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
        <p class="mb-5">
          <feather-icon :svgClasses="`${colorForCriteria(3)} h-4 w-4`" class="p-2" :icon="iconForCriteria(3)" />Un símbolo <br>
          <feather-icon :svgClasses="`${colorForCriteria(4)} h-4 w-4`" class="p-2" :icon="iconForCriteria(4)" />8 caracteres mínimo <br>
          <feather-icon :svgClasses="`${colorForCriteria(5)} h-4 w-4`" class="p-2" :icon="iconForCriteria(5)" />Sin letras ni números consecutivos <br>
        </p>
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-full mb-2">
        <p><strong>¿Cómo te enteraste de nosotros?</strong></p>
      </div>
      <div class="vx-col w-full mb-3">
        <ul class="centerx">
          <li class="mt-2">
            <vs-radio v-model="model.source" vs-value="familiar">Me platicó un amigo/conocido/familiar</vs-radio>
          </li>
          <li class="mt-2">
            <vs-radio v-model="model.source" vs-value="search_engine">Google u otro motor de búsqueda</vs-radio>
          </li>
          <li class="mt-2">
            <vs-radio v-model="model.source" vs-value="social_networks">Redes sociales (Facebook, Youtube, Twitter, etc.)</vs-radio>
          </li>
          <li class="mt-2">
            <vs-radio v-model="model.source" vs-value="publication">Nota en periódico/revista/blog</vs-radio>
          </li>
          <li class="mt-2">
            <vs-radio v-model="model.source" vs-value="other">Otro</vs-radio>
            <div :class="colClass" v-if="model.source === 'other'">
              <vs-input
                class="w-full"
                label=""
                name="other_source"
                v-validate="requiredRules"
                v-model.lazy="model.other_source"
                :danger="hasError('other_source')"
                :danger-text="errorText('other_source')"
                :success="isSuccess('other_source')"
                icon-pack="feather"
                placeholder="¿Cómo?"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>

    <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mb-5" color="danger">
      <span class="font-regular">{{errorMssg}}</span>
    </vs-alert>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
const textType = "text";
const iconEye = "icon-eye";
const iconEyeOff = "icon-eye-off";
export default {
  name: "UserDataForm",
  mixins: [inputHelper],
  data(){
    return {
      colClass: "vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5",
      requiredRules: 'required',
      requiredPlaceholder: '(Requerido)',
      emailRules: 'required|email',
      emailPlaceholder: '(Requerido) Ingresa una dirección de correo válida',
      optionalRules: '',
      passInputPassMode: true,
      confirmPassInputPassMode: true,
      errorMssg: null,
      criteria: [false, false, false, false, false, false, false],
      passwordError: null,
      model: {
        alias: null,
        email: null,
        password: null,
        confirmed_password: null,
        source: null,
        other_source: null,
      }
    }
  },
  computed: {
    passInputType(){
      return this.passInputPassMode ? "password" : textType;
    },
    passInputIcon(){
      return this.passInputPassMode ? iconEye : iconEyeOff;
    },
    confirmPassInputType(){
      return this.confirmPassInputPassMode ? "password" : textType;
    },
    confirmPassInputIcon(){
      return this.confirmPassInputPassMode ? iconEye : iconEyeOff;
    },
    passwordFieldValid(){
      return this.testPassword(this.model.password);
    },
    confirmPasswordFieldValid(){
      return this.model.password === this.model.confirmed_password && this.model.confirmed_password !=  null;
    },
  },
  methods: {
    getModel(){
      return this.model;
    },
    async validateForm(){
      this.errorMssg = null;
      let r1 = await this.$validator.validateAll();
      if(r1){
        let r2 = this.validatePassword();
        if(r2){
          return this.validateSource();
        }
      }
      return false;
    },
    validateSource(){
      if(this.model.source === null){
        this.errorMssg = "Por favor indica cómo te enteraste de nosotros";
        return false;
      }
      else {
        if(this.model.source === "other"){
          if(this.model.other_source === null){
            this.errorMssg = "Por favor indica cómo te enteraste de nosotros";
            return false;
          }
        }
        return true;
      }
    },
    validateAlias(){

    },
    validateEmail(){

    },
    colorForCriteria(idx){
      return this.criteria[idx] ? 'text-success' : 'text-danger';
    },
    iconForCriteria(idx){
      return this.criteria[idx] ? 'CheckCircleIcon' : 'CircleIcon';
    },
    completePasswordForm(){
      return this.model.password != null && this.model.password.length >= 8;
    },
    completeConfirmPassword(){
      return this.model.confirmed_password != null && this.model.confirmed_password.length >= 8;
    },
    validatePassword(){
      if(this.model.password !== this.model.confirmed_password){
        this.errorMssg = "Las contraseñas no coinciden";
      }
      else {
        if(this.testPassword(this.model.password)){
          return true;
        }
        else {
          this.errorMssg = "La contraseña no cumple los requerimientos";
        }
      }
      return false;
    },
    testPassword(pass){
      if(pass === null || pass === undefined){
        this.criteria[0] = false;
        this.criteria[1] = false;
        this.criteria[2] = false;
        this.criteria[3] = false;
        this.criteria[4] = false;
        this.criteria[5] = false;
        this.criteria[6] = false;
        return false;
      }
      //let pass = this.pw1
      const number = /.*[0-9].*/
      const lower = /.*[a-z].*/
      const upper = /.*[A-Z].*/
      const special = /.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~].*/
      const consecutive = ['abcd', 'bcde', 'cdef', 'defg', 'efgh', 'fghi', 'ghij', 'hijk', 'ijkl', 'jklm', 'klmn', 'lmnñ', 'mnño', 'nñop', 'ñopq', 'opqr', 'pqrs', 'qrst', 'rstu', 'stuv', 'tuvw', 'uvwx', 'vwxy', 'wxyz',
        '0123', '1234', '2345', '3456', '4567', '5678', '6789', '7890', '8901', '9012', '2109', '1098', '0987', '9876', '8765', '7654', '6543', '5432', '4321', '3210',
        'aaaa', 'bbbb', 'cccc', 'dddd', 'eeee', 'ffff', 'gggg', 'hhhh', 'iiii', 'jjjj', 'kkkk', 'llll', 'mmmm', 'nnnn', 'ññññ', 'oooo', 'pppp', 'qqqq', 'rrrr', 'ssss', 'tttt', 'uuuu', 'vvvv', 'wwww', 'xxxx', 'yyyy', 'zzzz',
        '1111', '2222', '3333', '5555', '6666', '7777', '8888', '9999',
        '!!!!', '""""', '####', '$$$$', '%%%%', '&&&&', '((((', '))))', '****', '++++', ',,,,', '----', '....', '////', '::::', ';;;;', '<<<<', '====', '>>>>',
        '????', '@@@@', '[[[[', ']]]]', '^^^^', '____', '````', '{{{{', '||||', '}}}}', '~~~~']

      this.criteria[0] = lower.test(pass);
      this.criteria[1] = upper.test(pass);
      this.criteria[2] = number.test(pass);
      this.criteria[3] = special.test(pass);
      this.criteria[4] = pass.length > 7;
      let no_consecutive = true
      for(let i = 0; i < consecutive.length; i ++){
        if(pass.toLowerCase().includes(consecutive[i])){
          no_consecutive = false
        }
      }
      this.criteria[5] = no_consecutive

      return (this.criteria[0] && this.criteria[1] && this.criteria[2] && this.criteria[3] && this.criteria[4] && this.criteria[5]);
    },
    togglePassInputType(){
      this.passInputPassMode = !this.passInputPassMode;
    },
    toggleConfirmPassInputType(){
      this.confirmPassInputPassMode = !this.confirmPassInputPassMode;
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);
    },
  }
}
</script>
